define("discourse/plugins/partly-discourse-plugin/utils/debounce", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.debounce = debounce;
  function debounce(func) {
    let delay = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 500;
    let timeoutId;
    return function () {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }
      const context = this;
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func.apply(context, args), delay);
    };
  }
});