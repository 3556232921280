define("discourse/plugins/partly-discourse-plugin/utils/index", ["exports", "discourse/plugins/partly-discourse-plugin/utils/cases", "discourse/plugins/partly-discourse-plugin/utils/consts", "discourse/plugins/partly-discourse-plugin/utils/debounce", "discourse/plugins/partly-discourse-plugin/utils/is-defined", "discourse/plugins/partly-discourse-plugin/utils/unique"], function (_exports, _cases, _consts, _debounce, _isDefined, _unique) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.keys(_cases).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    if (key in _exports && _exports[key] === _cases[key]) return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function () {
        return _cases[key];
      }
    });
  });
  Object.keys(_consts).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    if (key in _exports && _exports[key] === _consts[key]) return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function () {
        return _consts[key];
      }
    });
  });
  Object.keys(_debounce).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    if (key in _exports && _exports[key] === _debounce[key]) return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function () {
        return _debounce[key];
      }
    });
  });
  Object.keys(_isDefined).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    if (key in _exports && _exports[key] === _isDefined[key]) return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function () {
        return _isDefined[key];
      }
    });
  });
  Object.keys(_unique).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    if (key in _exports && _exports[key] === _unique[key]) return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function () {
        return _unique[key];
      }
    });
  });
});