define("discourse/plugins/partly-discourse-plugin/discourse/components/utils/get-part-type-details", ["exports", "discourse/plugins/partly-discourse-plugin/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getPartTypeDetails = getPartTypeDetails;
  function getPartTypeDetails(partType) {
    const details = {};
    if (!partType) {
      return details;
    }
    if (partType.description) {
      details.description = partType.description;
    }
    if ((0, _utils.isDefined)(partType.is_multi_purpose)) {
      details.use = partType.is_multi_purpose ? "Multi-purpose" : "Single-purpose";
    }
    const assemblyClassification = partType.assembly_classification;
    if (assemblyClassification) {
      details.unitOfMeasure = (0, _utils.pascalCaseToSentenceCase)(assemblyClassification);
    }
    const department = partType.department;
    if (department) {
      details.kind = (0, _utils.pascalCaseToSentenceCase)(department);
    }
    const primaryFitmentClassification = partType.primary_fitment_classification;
    const secondaryFitmentClassification = partType.secondary_fitment_classification;
    if (primaryFitmentClassification && secondaryFitmentClassification) {
      details.primaryFitment = (0, _utils.pascalCaseToSentenceCase)(primaryFitmentClassification);
      details.secondaryFitment = (0, _utils.pascalCaseToSentenceCase)(secondaryFitmentClassification);
    } else if (primaryFitmentClassification) {
      details.fitment = (0, _utils.pascalCaseToSentenceCase)(primaryFitmentClassification);
    } else if (secondaryFitmentClassification) {
      details.fitment = (0, _utils.pascalCaseToSentenceCase)(secondaryFitmentClassification);
    }
    const positionClassification = partType.position_classification;
    if (positionClassification) {
      details.position = (0, _utils.pascalCaseToSentenceCase)(positionClassification);
    }
    return details;
  }
});