define("discourse/plugins/partly-discourse-plugin/utils/is-defined", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isDefined = isDefined;
  function isDefined(value) {
    return value !== null && value !== undefined;
  }
});