define("discourse/plugins/partly-discourse-plugin/utils/consts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.pcdbRelation = _exports.pagesLimit = _exports.graphqlApiUrl = _exports.graphqlApiHeaders = _exports.discourseApiHeaders = _exports.coreApiUrl = _exports.coreApiHeaders = _exports.appUrl = void 0;
  const appUrl = _exports.appUrl = "https://openbase.partly.com/";
  const coreApiUrl = _exports.coreApiUrl = "https://api.partly.com/api/v1";
  // export const coreApiUrl = "https://api.dev2.partly.pro/api/v1";
  // export const coreApiUrl = "http://localhost:7000/api/v1";
  const graphqlApiUrl = _exports.graphqlApiUrl = "https://api.partly.com/node-api/graphql";
  // export const graphqlApiUrl = "https://api.dev2.partly.pro/node-api/graphql";

  const coreApiHeaders = _exports.coreApiHeaders = {
    Authorization: "",
    "Content-Type": "application/json"
  };
  const graphqlApiHeaders = _exports.graphqlApiHeaders = {
    "Content-Type": "application/json"
  };
  const discourseApiHeaders = _exports.discourseApiHeaders = {
    "Discourse-Logged-In": "true",
    "Discourse-Present": "true",
    Accept: "application/json"
  };
  const pcdbRelation = _exports.pcdbRelation = "Pcdb";
  const pagesLimit = _exports.pagesLimit = 5;
});