define("discourse/plugins/partly-discourse-plugin/discourse/components/utils/get-month-year-formatted", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getYearFormatted = void 0;
  const getYearFormatted = vehicle => {
    if (!vehicle) {
      return "";
    }
    const fromMonth = vehicle.uvdb_from_month?.name;
    const fromYear = vehicle.uvdb_from_year?.name;
    const toMonth = vehicle.uvdb_to_month?.name;
    const toYear = vehicle.uvdb_to_year?.name;
    if (fromYear === toYear && !fromMonth && !toMonth) {
      return `${fromYear}`;
    }
    if (fromYear) {
      if (fromMonth) {
        // starting month and year
        if (toYear) {
          if (toMonth) {
            // all ending parts
            return `${fromYear}/${fromMonth}–${toYear}/${toMonth}`;
          }
          // no ending month
          return `${fromYear}/${fromMonth}–${toYear}`;
        }
        // no ending year or month
        return `${fromYear}/${fromMonth}–present`;
      }
      if (toYear) {
        if (toMonth) {
          // all ending parts
          return `${fromYear}–${toYear}/${toMonth}`;
        }
        // no ending month
        return `${fromYear}–${toYear}`;
      }
      // no ending year or month
      return `${fromYear}–present`;
    }
    return "";
  };
  _exports.getYearFormatted = getYearFormatted;
});