define("discourse/plugins/partly-discourse-plugin/discourse/templates/connectors/before-header-panel/links", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div style="display: flex">
      {{#if this.showCatalogLink}}
          <div style="margin-left: 10px;">
              <a href="/catalog" class="btn btn-text btn-default bootstrap-mode">
                  <img src="/plugins/partly-discourse-plugin/images/catalog-icon-black.svg" height="16px"
                       style="margin-right: 4px" alt=""/>
                  <span class="d-button-label">Search the Parts Standard</span>
              </a>
          </div>
      {{/if}}
      {{#if this.showVehiclesLink}}
          <div style="margin-left: 10px;">
              <a href="/vehicles" class="btn btn-text btn-default bootstrap-mode">
                  <img src="/plugins/partly-discourse-plugin/images/uvdb-icon-black.svg" height="16px"
                       style="margin-right: 4px" alt=""/>
                  <span class="d-button-label">Search the Vehicle Standard</span>
              </a>
          </div>
      {{/if}}
  </div>
  */
  {
    "id": "PXKLg5Vz",
    "block": "[[[10,0],[14,5,\"display: flex\"],[12],[1,\"\\n\"],[41,[30,0,[\"showCatalogLink\"]],[[[1,\"        \"],[10,0],[14,5,\"margin-left: 10px;\"],[12],[1,\"\\n            \"],[10,3],[14,6,\"/catalog\"],[14,0,\"btn btn-text btn-default bootstrap-mode\"],[12],[1,\"\\n                \"],[10,\"img\"],[14,\"src\",\"/plugins/partly-discourse-plugin/images/catalog-icon-black.svg\"],[14,\"height\",\"16px\"],[14,5,\"margin-right: 4px\"],[14,\"alt\",\"\"],[12],[13],[1,\"\\n                \"],[10,1],[14,0,\"d-button-label\"],[12],[1,\"Search the Parts Standard\"],[13],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"showVehiclesLink\"]],[[[1,\"        \"],[10,0],[14,5,\"margin-left: 10px;\"],[12],[1,\"\\n            \"],[10,3],[14,6,\"/vehicles\"],[14,0,\"btn btn-text btn-default bootstrap-mode\"],[12],[1,\"\\n                \"],[10,\"img\"],[14,\"src\",\"/plugins/partly-discourse-plugin/images/uvdb-icon-black.svg\"],[14,\"height\",\"16px\"],[14,5,\"margin-right: 4px\"],[14,\"alt\",\"\"],[12],[13],[1,\"\\n                \"],[10,1],[14,0,\"d-button-label\"],[12],[1,\"Search the Vehicle Standard\"],[13],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[13]],[],false,[\"if\"]]",
    "moduleName": "discourse/plugins/partly-discourse-plugin/discourse/templates/connectors/before-header-panel/links.hbs",
    "isStrictMode": false
  });
});