define("discourse/plugins/partly-discourse-plugin/discourse/partly-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    this.route('catalog', {
      path: '/catalog'
    });
    this.route('category-tree', {
      path: '/catalog/category-tree'
    });
    this.route('vehicles', {
      path: '/vehicles'
    });
  }
});