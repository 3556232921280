define("discourse/plugins/partly-discourse-plugin/utils/enums", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.VehicleTypes = void 0;
  const VehicleTypes = _exports.VehicleTypes = {
    Default: "Default",
    ATV: "ATV",
    Motorbike: "Motorbike",
    Marine: "Marine",
    Snowmobiles: "Snowmobiles",
    OffroadMotorcycles: "OffroadMotorcycles",
    Scooters: "Scooters",
    Trailers: "Trailers",
    Commercial: "Commercial",
    Industrial: "Industrial",
    Unspecified: "Unspecified",
    UTV: "UTV"
  };
});