define("discourse/plugins/partly-discourse-plugin/discourse/components/subcategory-tree-item", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "discourse/plugins/partly-discourse-plugin/utils", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _utils, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <li>
      <span class={{if this.isExpanded "tree-caret tree-caret-down" "tree-caret"}} {{on "click" this.toggle}}>
          {{@subcategory.name}}
      </span>
      <ul class={{if this.isExpanded "active" "nested"}}>
          {{#if this.isLoading}}
              {{i18n "loading"}}
          {{/if}}
          {{#each this.partTypes as |partType|}}
              <li><a href="/catalog?ptid={{partType.id}}">{{partType.name}}</a></li>
          {{/each}}
      </ul>
  </li>
  */
  {
    "id": "ze1UHwy0",
    "block": "[[[10,\"li\"],[12],[1,\"\\n    \"],[11,1],[16,0,[52,[30,0,[\"isExpanded\"]],\"tree-caret tree-caret-down\",\"tree-caret\"]],[4,[38,1],[\"click\",[30,0,[\"toggle\"]]],null],[12],[1,\"\\n        \"],[1,[30,1,[\"name\"]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"ul\"],[15,0,[52,[30,0,[\"isExpanded\"]],\"active\",\"nested\"]],[12],[1,\"\\n\"],[41,[30,0,[\"isLoading\"]],[[[1,\"            \"],[1,[28,[35,2],[\"loading\"],null]],[1,\"\\n\"]],[]],null],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"partTypes\"]]],null]],null],null,[[[1,\"            \"],[10,\"li\"],[12],[10,3],[15,6,[29,[\"/catalog?ptid=\",[30,2,[\"id\"]]]]],[12],[1,[30,2,[\"name\"]]],[13],[13],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n\"],[13]],[\"@subcategory\",\"partType\"],false,[\"if\",\"on\",\"i18n\",\"each\",\"-track-array\"]]",
    "moduleName": "discourse/plugins/partly-discourse-plugin/discourse/components/subcategory-tree-item.hbs",
    "isStrictMode": false
  });
  class CategoryTreeItemComponent extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "isExpanded", [_tracking.tracked], function () {
      return false;
    }))();
    #isExpanded = (() => (dt7948.i(this, "isExpanded"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "partTypes", [_tracking.tracked], function () {
      return [];
    }))();
    #partTypes = (() => (dt7948.i(this, "partTypes"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "isLoading", [_tracking.tracked], function () {
      return false;
    }))();
    #isLoading = (() => (dt7948.i(this, "isLoading"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "error", [_tracking.tracked], function () {
      return null;
    }))();
    #error = (() => (dt7948.i(this, "error"), void 0))();
    constructor(args) {
      super(...arguments);
    }
    async loadPartTypes() {
      try {
        this.error = null;
        this.isLoading = true;
        const response = await fetch(_utils.graphqlApiUrl, {
          method: "POST",
          headers: _utils.graphqlApiHeaders,
          body: JSON.stringify({
            query: `query SearchPartTypesOpenbase($categoryId: ID, $subcategoryId: ID) {
                    gapc {
                      search_gapc_part_types(gapc_category_id: $categoryId, gapc_subcategory_id: $subcategoryId, limit: 500) {
                        items {
                          id
                          name
                        }
                      }
                    }
                  }`,
            variables: {
              categoryId: this.args.category.id,
              subcategoryId: this.args.subcategory.id
            }
          })
        });
        const result = await response.json();
        this.partTypes = result.data?.gapc?.search_gapc_part_types?.items?.sort((a, b) => a.name.localeCompare(b.name));
        if (!this.partTypes) {
          this.error = result?.errors?.map(each => each.message)?.join(" | ") || "Error fetching data.";
        }
        this.isLoading = false;
      } catch (e) {
        console.error(e);
        this.error = e;
        this.isLoading = false;
      }
    }
    toggle = () => {
      this.isExpanded = !this.isExpanded;
      if (this.isExpanded && !this.partTypes.length) {
        this.loadPartTypes().catch(e => console.error(e));
      }
    };
  }
  _exports.default = CategoryTreeItemComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, CategoryTreeItemComponent);
});