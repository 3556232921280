define("discourse/plugins/partly-discourse-plugin/discourse/templates/category-tree", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <CategoryTree/>
  */
  {
    "id": "W5vJjDCG",
    "block": "[[[8,[39,0],null,null,null]],[],false,[\"category-tree\"]]",
    "moduleName": "discourse/plugins/partly-discourse-plugin/discourse/templates/category-tree.hbs",
    "isStrictMode": false
  });
});