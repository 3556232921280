define("discourse/plugins/partly-discourse-plugin/utils/cases", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.pascalCaseToSentenceCase = pascalCaseToSentenceCase;
  function pascalCaseToSentenceCase(str) {
    if (!str) {
      return str;
    }

    // Insert spaces before capital letters
    let result = str.replace(/([A-Z])/g, " $1").trim();

    // Convert the first character to uppercase and the rest to lowercase
    result = result.charAt(0).toUpperCase() + result.slice(1).toLowerCase();
    return result;
  }
});