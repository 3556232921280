define("discourse/plugins/partly-discourse-plugin/utils/unique", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unique = unique;
  function unique(arr, isEqual) {
    const uniqueElements = [];
    if (!arr?.length) {
      return arr;
    }
    for (let i = 0; i < arr.length; i++) {
      const element = arr[i];
      if (isEqual) {
        let hasEqualElement = false;
        for (let j = 0; j < uniqueElements.length; j++) {
          if (isEqual(element, uniqueElements[j])) {
            hasEqualElement = true;
          }
        }
        if (!hasEqualElement) {
          uniqueElements.push(element);
        }
      } else if (!uniqueElements.includes(element)) {
        uniqueElements.push(element);
      }
    }
    return uniqueElements;
  }
});