define("discourse/plugins/partly-discourse-plugin/discourse/connectors/before-header-panel/links", ["exports", "@glimmer/component", "@ember/service", "@ember/object"], function (_exports, _component, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Links extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.inject]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    get showCatalogLink() {
      return this.router.currentRouteName !== "catalog";
    }
    static #_2 = (() => dt7948.n(this.prototype, "showCatalogLink", [(0, _object.computed)("router.currentURL")]))();
    get showVehiclesLink() {
      return this.router.currentRouteName !== "vehicles";
    }
    static #_3 = (() => dt7948.n(this.prototype, "showVehiclesLink", [(0, _object.computed)("router.currentURL")]))();
  }
  _exports.default = Links;
});